/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-darkgray {
  background-color: #292929;
}

.bg-darkgray-900 {
  background-color: #3b3b3b;
}

.bg-gray-700 {
  background-color: #585858;
}

.bg-gray-800 {
  background-color: #323232;
}

.bg-gray-900 {
  background-color: #242424;
}

.bg-light-red {
  background-color: #fa9da2;
}

.border-gray-600 {
  border-color: #707070;
}

.border-gray-700 {
  border-color: #585858;
}

.border-red {
  border-color: #da1e28;
}

.divide-gray-800 {
  border-color: #323232;
}
.text-gray-200 {
  color: "#CFCFCF";
}

.text-gray-300 {
  color: #b8b8b8;
}

.text-gray-600 {
  color: #707070;
}

.text-gray-700 {
  color: #585858;
}

.hover\:bg-darkgray-900:hover {
  background-color: rgba(69, 69, 69, 0.5);
}

.text-opacity-50 {
  --tw-text-opacity: 0.5;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-Thin.woff2") format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Thin.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-ThinItalic.woff2")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Thin.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-ExtraLight.woff2")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-ExtraLight.woff")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-ExtraLightItalic.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-ExtraLightItalic.woff?v=3.18")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-Light.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Light.woff?v=3.18")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-LightItalic.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-LightItalic.woff?v=3.18")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-Regular.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Regular.woff?v=3.18")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-Italic.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Italic.woff?v=3.18")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-Medium.woff2")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-MediumItalic.woff2")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-MediumItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-SemiBold.woff2")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-SemiBoldItalic.woff2")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-SemiBoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-Bold.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Bold.woff?v=3.18")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-BoldItalic.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-BoldItalic.woff?v=3.18")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-ExtraBold.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-ExtraBold.woff?v=3.18")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-ExtraBoldItalic.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-ExtraBoldItalic.woff?v=3.18")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-Black.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-Black.woff?v=3.18")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("https://cdn.localkitchens.co/font/Inter-BlackItalic.woff2?v=3.18")
      format("woff2"),
    url("https://cdn.localkitchens.co/font/Inter-BlackItalic.woff?v=3.18")
      format("woff");
}
